import { mealRequestApi as api } from './mealRequestApi';
export const addTagTypes = ['matching'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      matchingMealRequestList: build.query<
        MatchingMealRequestListApiResponse,
        MatchingMealRequestListApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            status: queryArg.status,
            district__uuid: queryArg.districtUuid
          }
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestCreate: build.mutation<
        MatchingMealRequestCreateApiResponse,
        MatchingMealRequestCreateApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/`,
          method: 'POST',
          body: queryArg.mealRequest
        }),
        invalidatesTags: ['matching']
      }),
      matchingMealRequestBulkMealRequestUpdate: build.mutation<
        MatchingMealRequestBulkMealRequestUpdateApiResponse,
        MatchingMealRequestBulkMealRequestUpdateApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/bulk_meal_request_update/`,
          method: 'PATCH',
          body: queryArg.body
        }),
        invalidatesTags: ['matching']
      }),
      matchingMealRequestMatchedUnmatchedRequests: build.query<
        MatchingMealRequestMatchedUnmatchedRequestsApiResponse,
        MatchingMealRequestMatchedUnmatchedRequestsApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/matched_unmatched_requests/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            r_type: queryArg.rType,
            district__uuid: queryArg.districtUuid,
            status: queryArg.status
          }
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestMyMatches: build.query<
        MatchingMealRequestMyMatchesApiResponse,
        MatchingMealRequestMyMatchesApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/my_matches/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            r_type: queryArg.rType,
            district__uuid: queryArg.districtUuid,
            status: queryArg.status
          }
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestRead: build.query<
        MatchingMealRequestReadApiResponse,
        MatchingMealRequestReadApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/${queryArg.uuid}/`
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestPartialUpdate: build.mutation<
        MatchingMealRequestPartialUpdateApiResponse,
        MatchingMealRequestPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.mealRequest
        }),
        invalidatesTags: ['matching']
      }),
      matchingMealRequestArchiveList: build.query<
        MatchingMealRequestArchiveListApiResponse,
        MatchingMealRequestArchiveListApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request_archive/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            status: queryArg.status,
            district__uuid: queryArg.districtUuid
          }
        }),
        providesTags: ['matching']
      }),
      matchingVolunteerRequesterMapList: build.query<
        MatchingVolunteerRequesterMapListApiResponse,
        MatchingVolunteerRequesterMapListApiArg
      >({
        query: queryArg => ({
          url: `/matching/volunteer_requester_map/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            district__uuid: queryArg.districtUuid
          }
        }),
        providesTags: ['matching']
      }),
      matchingGetDetailsView: build.query<
        MatchingGetDetailsViewApiResponse,
        MatchingGetDetailsViewApiArg
      >({
        query: queryArg => ({
          url: `/matching/volunteer_requester_map/${queryArg.uuid}/${queryArg['type']}/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['matching']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type MatchingMealRequestListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Filter users by status */
  districtUuid?: string;
  status?: string;
};
export type MatchingMealRequestCreateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestCreateApiArg = {
  mealRequest: MealRequest;
};
export type MatchingMealRequestBulkMealRequestUpdateApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestBulkMealRequestUpdateApiArg = {
  body: {
    /** Unique identifier */
    uuid: string;
    /** Status of the object */
    status: string;
  }[];
};
export type MatchingMealRequestMatchedUnmatchedRequestsApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestMatchedUnmatchedRequestsApiArg = {
  status?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  rType?: 'matched' | 'unmatched' | 'approved' | 'archive';
  /** Filter users by status */
  districtUuid?: string;
};
export type MatchingMealRequestMyMatchesApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestMyMatchesApiArg = {
  /** Filter users by status */
  status?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Request Type */
  rType?: string;
  /** Filter users by status */
  districtUuid?: string;
};
export type MatchingMealRequestReadApiResponse =
  /** status 200 Custom Response */ {
    results: MealRequestRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestReadApiArg = {
  uuid: string;
};
export type MatchingMealRequestPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: MealRequestRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestPartialUpdateApiArg = {
  uuid: string;
  mealRequest: MealRequest;
};
export type MatchingMealRequestArchiveListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestArchiveListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Filter users by status */
  status?: string;
  /** Filter users by status */
  districtUuid?: string;
};
export type MatchingVolunteerRequesterMapListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserLocationRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingVolunteerRequesterMapListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  districtUuid?: string;
};

export type MatchingMapType = {
  first_name?: string;
  last_name?: string;
  cb_address?: string;
  cb_mamacap?: number;
  cb_mamacooks?: string;
  cb_mamafillin?: string;
  cb_mamaradius?: number;
  cb_mamaradiustype?: string;
  cb_schedule?: string;
  city?: string;
  contact_number?: string;
  country?: string;
  email?: string;
  state?: string;
  street_address?: string;
  uuid?: string;
  zip_code?: string;
  about?: string;
  address?: string;
  adults?: string;
  allergyinfo?: string;
  co?: string;
  dairyfree?: boolean;
  firstname?: string;
  glutenfree?: boolean;
  kids?: number;
  lastname?: string;
  mobile?: string;
  nutallergy?: boolean;
  otherallergy?: boolean;
  requestdate?: string;
  restrictions?: boolean;
  vegan?: boolean;
  vegetarian?: boolean;
  zip?: string;
};
export type MatchingGetDetailsViewApiResponse =
  /** status 200 Custom Response */ {
    results: MatchingMapType;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingGetDetailsViewApiArg = {
  uuid: string;
  type: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ProfileList = {
  gender?:
    | ('Male' | 'Female' | 'Prefer not to say' | 'Transgender' | 'Non-Binary')
    | null;
  birth_date?: string | null;
  contact_number?: string | null;
  street_address?: string | null;
  cb_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
  country_name?: string;
};
export type ProfileListRead = {
  uuid?: string;
  gender?:
    | ('Male' | 'Female' | 'Prefer not to say' | 'Transgender' | 'Non-Binary')
    | null;
  birth_date?: string | null;
  over_18?: string;
  contact_number?: string | null;
  street_address?: string | null;
  cb_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
  country_name?: string;
};
export type MamaProfileLite = {
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradiustype?: string | null;
  cb_mamacooks?: string | null;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_hidedelivered?: string | null;
};
export type MamaProfileLiteRead = {
  uuid?: string;
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: string;
  cb_mamaradiustype?: string | null;
  cb_mamacooks?: string | null;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_hidedelivered?: string | null;
};
export type VolunteerDetail = {
  first_name?: string;
  last_name?: string;
  email: string;
  profile: ProfileList;
  mamaprofile?: MamaProfileLite;
};
export type VolunteerDetailRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  profile: ProfileListRead;
  mamaprofile?: MamaProfileLiteRead;
};
export type MealRequest = {
  status?:
    | 'schedule'
    | 'scheduled'
    | 'noresponse'
    | 'new'
    | 'delivered'
    | 'contacted'
    | 'cancelled';
  entrydate?: string | null;
  requestdate?: string | null;
  matchdate?: string | null;
  rdapprovedate?: string | null;
  approvedate?: string | null;
  scheduledate?: string | null;
  deliverydate?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  mobile?: string | null;
  email?: string | null;
  address?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  district?: string | null;
  co?: string | null;
  lat?: number | null;
  lon?: number | null;
  timezone?: string | null;
  adults?: number | null;
  kids?: number | null;
  familysize?: number | null;
  about?: string | null;
  restrictions?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  glutenfree?: boolean;
  dairyfree?: boolean;
  nutallergy?: boolean;
  otherallergy?: boolean;
  allergyinfo?: string | null;
  goodness?: boolean;
  matchmama?: VolunteerDetail;
  mamanotes?: string | null;
  priority?: number | null;
  latlonkey?: string | null;
  distance?: number | null;
  vetted?: number | null;
  source?: string | null;
  othersource?: string | null;
  matchregion?: string | null;
  matched_with?: string | null;
  latloncheck?: string | null;
  sendemail?: number | null;
  emailprefs?: string | null;
  goodness_carrabba?: string | null;
  country_origin?: string | null;
};
export type MealRequestRead = {
  uuid?: string;
  status?:
    | 'schedule'
    | 'scheduled'
    | 'noresponse'
    | 'new'
    | 'delivered'
    | 'contacted'
    | 'cancelled';
  entrydate?: string | null;
  requestdate?: string | null;
  matchdate?: string | null;
  rdapprovedate?: string | null;
  approvedate?: string | null;
  scheduledate?: string | null;
  deliverydate?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  mobile?: string | null;
  email?: string | null;
  address?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  district?: string | null;
  co?: string | null;
  lat?: number | null;
  lon?: number | null;
  timezone?: string | null;
  adults?: number | null;
  kids?: number | null;
  familysize?: number | null;
  about?: string | null;
  restrictions?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  glutenfree?: boolean;
  dairyfree?: boolean;
  nutallergy?: boolean;
  otherallergy?: boolean;
  allergyinfo?: string | null;
  goodness?: boolean;
  matchmama?: VolunteerDetailRead;
  mamanotes?: string | null;
  priority?: number | null;
  latlonkey?: string | null;
  distance?: number | null;
  vetted?: number | null;
  source?: string | null;
  othersource?: string | null;
  matchregion?: string | null;
  matched_with?: string | null;
  latloncheck?: string | null;
  sendemail?: number | null;
  emailprefs?: string | null;
  goodness_carrabba?: string | null;
  country_origin?: string | null;
};
export type MealRequestArchive = {
  status?:
    | 'schedule'
    | 'scheduled'
    | 'noresponse'
    | 'new'
    | 'delivered'
    | 'contacted'
    | 'cancelled';
  entrydate?: string | null;
  requestdate?: string | null;
  matchdate?: string | null;
  rdapprovedate?: string | null;
  approvedate?: string | null;
  scheduledate?: string | null;
  deliverydate?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  mobile?: string | null;
  email?: string | null;
  address?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  co?: string | null;
  lat?: number | null;
  lon?: number | null;
  timezone?: string | null;
  adults?: number | null;
  kids?: number | null;
  familysize?: number | null;
  about?: string | null;
  restrictions?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  glutenfree?: boolean;
  dairyfree?: boolean;
  nutallergy?: boolean;
  otherallergy?: boolean;
  allergyinfo?: string | null;
  goodness?: boolean;
  matchmama?: string | null;
  mamanotes?: string | null;
  priority?: number | null;
  latlonkey?: string | null;
  distance?: number | null;
  vetted?: number | null;
  source?: string | null;
  othersource?: string | null;
  matchregion?: string | null;
  matched_with?: string | null;
  latloncheck?: string | null;
  sendemail?: number | null;
  emailprefs?: string | null;
  goodness_carrabba?: string | null;
  country_origin?: string | null;
};
export type MealRequestArchiveRead = {
  uuid?: string;
  status?:
    | 'schedule'
    | 'scheduled'
    | 'noresponse'
    | 'new'
    | 'delivered'
    | 'contacted'
    | 'cancelled';
  entrydate?: string | null;
  requestdate?: string | null;
  matchdate?: string | null;
  rdapprovedate?: string | null;
  approvedate?: string | null;
  scheduledate?: string | null;
  deliverydate?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  mobile?: string | null;
  email?: string | null;
  address?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  co?: string | null;
  lat?: number | null;
  lon?: number | null;
  timezone?: string | null;
  adults?: number | null;
  kids?: number | null;
  familysize?: number | null;
  about?: string | null;
  restrictions?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  glutenfree?: boolean;
  dairyfree?: boolean;
  nutallergy?: boolean;
  otherallergy?: boolean;
  allergyinfo?: string | null;
  goodness?: boolean;
  matchmama?: string | null;
  mamanotes?: string | null;
  priority?: number | null;
  latlonkey?: string | null;
  distance?: number | null;
  vetted?: number | null;
  source?: string | null;
  othersource?: string | null;
  matchregion?: string | null;
  matched_with?: string | null;
  latloncheck?: string | null;
  sendemail?: number | null;
  emailprefs?: string | null;
  goodness_carrabba?: string | null;
  country_origin?: string | null;
};
export type UserLocation = {
  lat?: number;
  lon?: number;
};
export type UserLocationRead = {
  uuid?: string;
  lat?: number;
  lon?: number;
  type?: string;
};
export const {
  useMatchingMealRequestListQuery,
  useMatchingMealRequestCreateMutation,
  useMatchingMealRequestBulkMealRequestUpdateMutation,
  useMatchingMealRequestMatchedUnmatchedRequestsQuery,
  useMatchingMealRequestMyMatchesQuery,
  useMatchingMealRequestReadQuery,
  useMatchingMealRequestPartialUpdateMutation,
  useMatchingMealRequestArchiveListQuery,
  useMatchingVolunteerRequesterMapListQuery,
  useMatchingGetDetailsViewQuery
} = injectedRtkApi;
