import { routePaths } from '@utils/constants/routePaths';
import { t } from 'i18next';
export const limit = 10;

export const assetUploadStatus = {
  profile: 'profile_image',
  cover: 'cover_image',
  collaboration: 'collaboration',
  product: 'product',
  ticket: 'ticket'
};

export const regionalLeaderZipcode = [
  {
    uuid: '09bca997-9f1e-4f0a-8f09-259d894a0c31',
    name: 'New York',
    st: 'NY',
    districts_states: [
      {
        uuid: '3a2ee489-2036-4e2a-9041-f9a22d59d77f',
        name: 'Southern Tier/Southtown NY'
      },
      {
        uuid: '1bb74bc2-5e21-4947-b191-5eeb5a0e93e4',
        name: 'Long Island, NY'
      },
      {
        uuid: '821a7689-8ac7-4c05-b839-feca83e0be14',
        name: 'Lower Manhattan, NY'
      },
      {
        uuid: '36f87b29-79cf-424c-af5b-aa7ce75b21b7',
        name: 'Mohawk Valley, NY'
      },
      {
        uuid: '9d59e115-1774-47e5-8ba7-0f3b650c574c',
        name: 'North Westchester, NY'
      },
      {
        uuid: '3a7d962c-c5ae-4b58-b6fa-0fefd339047f',
        name: 'Oakfield, NY'
      },
      {
        uuid: 'b470ca30-6c42-4ce4-a53b-89fac5cf72d9',
        name: 'Onondaga County, NY'
      },
      {
        uuid: '488fda57-ea0d-49c9-a9d5-271ef4871e61',
        name: 'Putnam County, NY'
      },
      {
        uuid: '12a84912-b991-4042-ab66-ffa9df185c1f',
        name: 'Rockland County, NY'
      },
      {
        uuid: '2635b4e1-24a5-422b-9ba8-94774f741156',
        name: 'Staten Island, NY'
      },
      {
        uuid: 'cd704d01-d1e0-42b7-85d2-d52154213efe',
        name: 'Cortland County, NY'
      },
      {
        uuid: 'b5add3f5-0339-49f5-8d42-56390c0711dd',
        name: 'Oswego County, NY'
      },
      {
        uuid: '2266dcf5-ee0c-4067-b9b5-2feeec359d62',
        name: 'Columbia County, NY'
      },
      {
        uuid: 'be4857a7-9ca7-4762-836d-212ece9a696a',
        name: 'Greene County, NY'
      },
      {
        uuid: '8ab4b30c-ab7f-4acd-8320-5de3b9356263',
        name: 'Washington County, NY'
      },
      {
        uuid: '757b230f-16f5-49c4-a3a4-0941a1cba2d7',
        name: 'Otsego County, NY'
      },
      {
        uuid: 'ac0e2733-04eb-4db1-9034-a7a4a6c6092f',
        name: 'Schoharie County, NY'
      },
      {
        uuid: '6f9452a0-df69-4695-b14a-dd86aed1dda1',
        name: 'Queens, NY'
      }
    ]
  }
];

export const rolesAboveLocalLeader = ['Regional Director', 'Country Director'];
export const dateFormat = 'MM/DD/YYYY';
export const dateFormatWithTime = 'MM-DD-YYYY hh:mm A';
export const dateFormatWithDate = 'DD MMMM';
export const DATE_FORMAT_MMM_DD_YYYY = 'MMM DD, YYYY';
export const dateTimeFormatWithSlash = 'MM/DD/YY hh:mm A';
export const zipCodeRegex = /^\/|\/$/g;

export const statusColorCode = [
  {
    key: 'InProgress',
    value: 'inprogress',
    color: '#ff9f1c',
    createdAt: '2023-11-15T04:32:08.338Z',
    updatedAt: '2023-11-15T04:31:08.582Z'
  },
  {
    key: 'Completed',
    value: 'completed',
    color: '#99D980',
    createdAt: '2023-11-15T04:32:08.338Z',
    updatedAt: '2023-11-15T04:31:08.582Z'
  },
  {
    key: 'Pending',
    value: 'pending',
    color: '#C6C6C6',
    createdAt: '2023-11-15T04:32:08.338Z',
    updatedAt: '2023-11-15T04:31:08.582Z'
  },
  {
    key: 'Draft',
    value: 'draft',
    color: '#C6C6C6',
    createdAt: '2023-11-15T04:32:08.338Z',
    updatedAt: '2023-11-15T04:31:08.582Z'
  },
  {
    key: 'Rejected',
    value: 'rejected',
    color: '#FF0000',
    createdAt: '2023-11-15T04:32:08.338Z',
    updatedAt: '2023-11-15T04:31:08.582Z'
  }
];

export const schedulerRadioOptions = [
  { value: '1', label: 'Just this once' },
  { value: '7', label: 'Once a week' },
  { value: '14', label: 'Every other week' },
  { value: '28', label: 'Once a month' }
];

export const limitOptions = [
  {
    value: '10',
    label: '10'
  },
  {
    value: '20',
    label: '20'
  },
  {
    value: '50',
    label: '50'
  },
  {
    value: '100',
    label: '100'
  }
];

export const menuItem = [
  {
    no: 1,
    label: 'sidebar.user',
    href: routePaths.VolunteerList,
    permission: 'view_user_management',
    type: 'manage_admin',
    selected: false,
    urls: [
      routePaths.VolunteerList,
      routePaths.VolunteerPaused,
      routePaths.VolunteerPending,
      routePaths.VolunteerProfile,
      routePaths.AddVolunteer,
      routePaths.VolunteerDeleted
    ],
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.VolunteerProfile
      }
    ],
    child: [
      {
        no: 1,
        label: 'sidebar.allUsers',
        href: routePaths.VolunteerList,
        permission: 'view_user_management',
        selected: false
      },
      {
        no: 2,
        label: 'sidebar.addUser',
        href: routePaths.AddVolunteer,
        permission: 'view_user_management',
        selected: false
      },
      {
        no: 3,
        label: 'sidebar.pendingUsers',
        href: routePaths.VolunteerPending,
        permission: 'view_user_management',
        selected: false
      },
      {
        no: 4,
        label: 'sidebar.pausedVolunteer',
        href: routePaths.VolunteerPaused,
        permission: 'view_user_management',
        selected: false
      },
      {
        no: 5,
        label: 'sidebar.deletedUsers',
        href: routePaths.VolunteerDeleted,
        permission: 'view_user_management',
        selected: false
      }
    ]
  },
  {
    no: 19,
    label: 'sidebar.download',
    href: routePaths.DownloadCenter,
    permission: 'view_downloadcentre',
    type: 'manage_admin',
    selected: false,
    urls: [routePaths.DownloadCenter],
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.DownloadCenter
      }
    ]
  },
  {
    no: 2,
    label: 'resource.manageResource',
    href: routePaths.ManageResource,
    permission: 'add_help',
    urls: [routePaths.ManageResource],
    selected: false,
    type: 'manage_admin',
    child: [
      {
        no: 11,
        label: 'resource.training',
        href: routePaths.ManageResource,
        permission: 'add_help',
        selected: false
      },
      {
        no: 12,
        label: 'resource.faq',
        href: routePaths.ListFaq,
        permission: 'add_help',
        selected: false
      }
    ],
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.ManageResource
      }
    ]
  },
  {
    no: 3,
    label: 'sidebar.local',
    href: routePaths.LocalArea,
    urls: [
      routePaths.LocalArea,
      routePaths.LocalAreaDetails,
      routePaths.CreateLocalArea
    ],
    permission: 'view_manage_local_area',
    type: 'manage_admin',
    selected: false,
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.LocalArea
      }
    ]
  },
  {
    no: 4,
    label: 'sidebar.superRegions',
    href: '#',
    permission: 'view_manage_super_region',
    type: 'manage_admin',
    selected: false,
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.LocalArea
      }
    ]
  },
  {
    no: 5,
    label: 'sidebar.states',
    href: routePaths.StateList,
    urls: [
      routePaths.StateList,
      routePaths.CreateState,
      routePaths.UploadState,
      routePaths.StateDetails
    ],
    permission: 'view_manage_states',
    selected: false,
    type: 'manage_admin',
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.LocalArea
      }
    ]
  },
  {
    no: 6,
    label: 'sidebar.country',
    href: routePaths.CountryList,
    permission: 'view_manage_country',
    type: 'manage_admin',
    urls: [
      routePaths.CountryList,
      routePaths.CreateCountry,
      routePaths.CountryDetails
    ],
    selected: false,
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.LocalArea
      }
    ]
  },
  {
    no: 7,
    label: 'sidebar.zip',
    href: routePaths.ListZipCode,
    permission: 'view_zip_code_management',
    type: 'manage_admin',
    urls: [routePaths.ListZipCode, routePaths.UploadZipCode],
    selected: false,
    child: [
      {
        no: 11,
        label: 'zipCode.allocated',
        href: routePaths.ListZipCode,
        permission: 'view_zip_code_management',
        selected: false
      },
      {
        no: 12,
        label: 'zipCode.upload',
        href: routePaths.UploadZipCode,
        permission: 'view_zip_code_management',
        selected: false
      }
    ],
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.LocalArea
      }
    ]
  },
  {
    no: 16,
    label: 'sidebar.volunteerList',
    href: routePaths.VolunteerList,
    permission: 'view_only_user_list',
    urls: [routePaths.VolunteerList, routePaths.VolunteerPaused],
    selected: false,
    type: 'manage_admin',
    path: [
      {
        no: 1,
        label: t('sidebar.title'),
        href: routePaths.LocalArea
      }
    ],
    child: [
      {
        no: 1,
        label: 'Complete Volunteer Roster',
        href: routePaths.VolunteerList,
        permission: 'view_user_list',
        selected: false
      },
      {
        no: 2,
        label: 'All Paused Volunteer',
        href: routePaths.VolunteerPaused,
        permission: 'view_user_list',
        selected: false
      }
    ]
  },
  {
    no: 8,
    label: 'sidebar.profile',
    href: routePaths.Profile,
    urls: [routePaths.Profile],
    permission: 'view_profile',
    type: 'view_profile',
    selected: true,
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.Profile
      }
    ]
  },
  {
    no: 9,
    label: 'sidebar.preference',
    href: routePaths.Preferences,
    urls: [routePaths.Preferences],
    permission: 'view_my_preference_and_area',
    type: 'view_profile',
    selected: false,
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.Profile
      }
    ]
  },
  {
    no: 10,
    label: 'sidebar.schedule',
    href: routePaths.Schedules,
    urls: [routePaths.Schedules],
    permission: 'view_schedule',
    type: 'view_profile',
    selected: false,
    path: [
      {
        no: 20,
        label: t('sidebar.title'),
        href: routePaths.Profile
      }
    ]
  },
  {
    no: 11,
    label: 'sidebar.training',
    href: routePaths.Training,
    urls: [routePaths.Training],
    type: 'view_profile',
    permission: 'view_my_training',
    selected: false,
    path: [
      {
        no: 61,
        label: t('sidebar.title'),
        href: routePaths.Profile
      }
    ]
  },
  {
    no: 12,
    label: 'sidebar.introToLasagna',
    href: routePaths.HelpAndSupport,
    urls: [routePaths.HelpAndSupport],
    permission: 'view_help',
    resourceType: 'intro',
    selected: false,
    type: 'view_help',
    child: null as any,
    path: [
      {
        no: 20,
        label: t('header.help'),
        href: routePaths.HelpAndSupport
      }
    ]
  },
  {
    no: 15,
    label: 'sidebar.volunteerList',
    href: routePaths.VolunteerList,
    permission: 'view_user_list',
    type: 'view_resources',
    urls: [routePaths.VolunteerList, routePaths.VolunteerPaused],
    selected: false,
    path: [
      {
        no: 1,
        label: t('header.resources'),
        href: routePaths.LocalArea
      }
    ],
    child: [
      {
        no: 1,
        label: t('volunteer.myVolunteerRoster'),
        href: routePaths.VolunteerList,
        permission: 'view_user_list',
        selected: false
      },
      {
        no: 2,
        label: t('volunteer.myPausedVolunteers'),
        href: routePaths.VolunteerPaused,
        permission: 'view_user_list',
        selected: false
      }
    ]
  },
  {
    no: 22,
    label: 'sidebar.download',
    href: routePaths.DownloadCenter,
    permission: 'view_downloadcentre',
    type: 'view_resources',
    selected: false,
    urls: [routePaths.DownloadCenter],
    path: [
      {
        no: 1,
        label: t('header.resources'),
        href: routePaths.DownloadCenter
      }
    ]
  },
  {
    no: 13,
    label: 'sidebar.volunteerTraining',
    href: routePaths.HelpAndSupport,
    urls: [routePaths.HelpAndSupport],
    permission: 'view_help_volunteer',
    resourceType: 'Lasagna Chef',
    selected: false,
    type: 'view_help',
    child: null as any,
    path: [
      {
        no: 31,
        label: t('header.help'),
        href: routePaths.HelpAndSupport
      }
    ]
  },
  {
    no: 14,
    label: 'sidebar.LocalLeaderResource',
    href: routePaths.HelpAndSupport,
    urls: [routePaths.HelpAndSupport],
    permission: 'view_help_local_leader',
    selected: false,
    resourceType: 'local leader',
    type: 'view_help',
    child: null as any,
    path: [
      {
        no: 31,
        label: t('header.help'),
        href: routePaths.HelpAndSupport
      }
    ]
  },

  {
    no: 15,
    label: 'sidebar.regionalDirectorResource',
    href: routePaths.HelpAndSupport,
    urls: [routePaths.HelpAndSupport],
    permission: 'view_help_regional_director',
    selected: false,
    resourceType: 'regional director',
    type: 'view_help',
    child: null as any,
    path: [
      {
        no: 31,
        label: t('header.help'),
        href: routePaths.HelpAndSupport
      }
    ]
  },
  {
    no: 16,
    label: 'sidebar.countryDirectorResource',
    href: routePaths.HelpAndSupport,
    urls: [routePaths.HelpAndSupport],
    permission: 'view_help_country_director',
    selected: false,
    resourceType: 'country director',
    type: 'view_help',
    child: null as any,
    path: [
      {
        no: 31,
        label: t('header.help'),
        href: routePaths.HelpAndSupport
      }
    ]
  },

  {
    no: 17,
    label: 'faq',
    href: routePaths.FAQ,
    urls: [routePaths.FAQ],
    permission: 'view_faq',
    selected: false,
    type: 'view_help',
    child: null as any,
    path: [
      {
        no: 51,
        label: t('header.help'),
        href: routePaths.HelpAndSupport
      }
    ]
  },
  {
    no: 18,
    label: 'User Management',
    href: routePaths.ListZipCode,
    permission: '',
    urls: [
      routePaths.VolunteerList,
      routePaths.VolunteerPaused,
      routePaths.VolunteerPending,
      routePaths.VolunteerProfile,
      routePaths.AddVolunteer
    ],
    selected: false,
    child: [
      {
        no: 1,
        label: 'All Users',
        href: routePaths.VolunteerList,
        permission: '',
        selected: false
      },
      {
        no: 2,
        label: 'Add User',
        href: routePaths.AddVolunteer,
        permission: '',
        selected: false
      }
    ],
    path: [
      {
        no: 20,
        label: 'Pending User',
        href: routePaths.VolunteerPending
      }
    ],
    type: 'manage_admin'
  },
  {
    no: 19,
    label: t('sidebar.localAreaList'),
    href: routePaths.LocalAreaList,
    permission: 'view_local_area_list',
    urls: [routePaths.LocalAreaList],
    selected: false,
    type: 'view_resources',
    path: [
      {
        no: 1,
        label: t('header.resources'),
        href: routePaths.LocalAreaList
      }
    ]
  },
  {
    no: 20,
    label: t('sidebar.localAreaAssignment'),
    href: routePaths.LocalAreaAssignment,
    permission: 'view_local_area_assignment',
    urls: [routePaths.LocalAreaAssignment],
    selected: false,
    type: 'view_resources',
    path: [
      {
        no: 1,
        label: t('header.resources'),
        href: routePaths.LocalAreaAssignment
      }
    ]
  },

  {
    no: 21,
    label: t('sidebar.contacts'),
    href: routePaths.Contacts,
    permission: 'view_contacts',
    type: 'view_resources',
    urls: [routePaths.Contacts],
    selected: false,
    path: [
      {
        no: 1,
        label: t('header.resources'),
        href: routePaths.Contacts
      }
    ],
    child: [
      {
        no: 1,
        label: t('sidebar.regionalDirector'),
        href: routePaths.Contacts,
        permission: 'view_contacts',
        selected: false
      },
      {
        no: 2,
        label: t('sidebar.localLeader'),
        href: routePaths.Contacts,
        permission: 'view_contacts',
        selected: false
      },
      {
        no: 3,
        label: t('sidebar.lasagnaChefs'),
        href: routePaths.Contacts,
        permission: 'view_contacts',
        selected: false
      },
      {
        no: 4,
        label: t('sidebar.countryDirector'),
        href: routePaths.Contacts,
        permission: 'view_contacts',
        selected: false
      }
    ]
  },

  { label: 'sidebar.signOut', href: '#', permission: 'signOut' }
];

declare global {
  interface SVGElement {
    getBBox(): DOMRect;
  }
}

export const adminRoles = ['WebMaster'];
